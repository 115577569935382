import Route from 'mewe/routes/route';
import { getQueryStringParams } from 'mewe/shared/utils';
import config from 'mewe/config/environment';

export default Route.extend({
  model(params) {

    if (config.environment === 'prod') {
      return this.transitionTo('app.myworld');
    }

    const urlParams = getQueryStringParams();

    return {
      applicationId: params.application_id,
      urlParams
    };
  }
});