import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import PostApi from 'mewe/api/post-api';
import { dropTask } from 'ember-concurrency';
import { A } from '@ember/array';
import { service } from '@ember/service';
export default class MwTrendingHashtag extends Component {
  @service analytics;

  @tracked tags = A();
  @tracked selectedTag = null;

  @dropTask
  *loadTags() {
    const response = yield PostApi.getTrendingHashTags();
    this.tags.pushObjects(response.hashtags);
    this.selectedTag = {name: this.args.tag};
  }

  @action
  handleClick(tag, index) {
    this.selectedTag = tag;
    this.args.openHashTagFromTop(tag.name);
    const position = index + 1;
    this.analytics.sendEvent('trendingHashtagClicked', { hashtag: tag.name, position, posts_count: tag.total, context: 'home' });
  }

  @action
  updateSelectedTag() {
    if (this.args.tag === null) {
      this.selectedTag = null;
    }

    this.selectedTag = this.tags.find(tag => tag.name === this.args.tag);
  }

}
