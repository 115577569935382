import { A } from '@ember/array';
import TwitterText from 'twitter-text';
import PostApi from 'mewe/api/post-api';
import { Theme } from 'mewe/constants';
import { maxHashtags } from 'mewe/constants';
import { each } from 'lodash';

const shouldAutocompleteHashtag = (term) => {
  // checking if there are any other characters than digits or underscore which can appear
  // appear in hashtag but it will be valid only when there is also a letter character
  const termHasDigitsOnly = term.match(/^[0-9,_]+$/) !== null;

  // don't close autocomplete when there are only digits or underscore in search term (SG-31581)
  // because e.g. #123 is not a valid hashtag but user still can type a letter afterwards and e.g. #123a will be a valid one
  if (term !== '' && !termHasDigitsOnly && !TwitterText.isValidHashtag(`#${term}`)) return false;
  else return true;
};

export default {
  getHashtagStrategy(scope, scopeId) {
    return {
      id: 'hashtag',
      search: function (term, callback) {
        if (!shouldAutocompleteHashtag(term)) return callback(A(), false);

        const params = {
          term: term,
          maxResults: 5, // because we see first 5 anyway
        };

        if (scope === Theme.PRIVATEPOSTS || scope === Theme.MYCLOUD) {
          return callback(A(), false);
        }

        if (scope === 'myworld' || scope === Theme.PROFILE) {
          scope = Theme.CONTACTS;
        }

        PostApi.getHashTags(params, scope, scopeId)
          .then((data) => callback(data.hashtags))
          .catch(() => callback(A(), false));
      },
      template: (ht) =>
        '<span class="ht_name">#' +
        ht.name +
        "</span> <span class='total'>" +
        __('{count} Posts', { count: ht.total }) +
        '</span>',
    };
  },

  enrichHashtag: function (text) {
    let richText = '';

    if (text) {
      // hashtags sorted by order of appearance
      let entities = TwitterText.extractHashtagsWithIndices(text)
          .sort((a, b) => a.indices[0] - b.indices[0])
          .splice(0, maxHashtags),
        beginIndex = 0;

      each(entities, (e) => {
        richText += text.substring(beginIndex, e.indices[0]);
        richText += `<a class="h-hashtag post_text_hashtag">#${e.hashtag}</a>`;
        beginIndex = e.indices[1];
      });

      if (text) richText += text.substring(beginIndex, text.length);
    }

    return richText;
  },

  stripHashtags: function (text) {
    // font is probably because we used to have a problem long time ago where ios would add <font ...></font>
    // into the contenteditable text - not sure if still valid (MW)
    const fontRegexp = /<font color="#.*?">(.*?|\s)<\/font>/;
    let fontMatch = text.match(fontRegexp);

    while (fontMatch) {
      text = text.replace(fontMatch[0], fontMatch[1]);
      fontMatch = text.match(fontRegexp);
    }

    const hashtagRegexp = /<a class="h-hashtag post_text_hashtag"(\sstyle="")?>(.*?|\s)<\/a>/;
    let hashtagMatch = text.match(hashtagRegexp);

    while (hashtagMatch) {
      text = text.replace(hashtagMatch[0], hashtagMatch[2] || hashtagMatch[1] || '');
      hashtagMatch = text.match(hashtagRegexp);
    }

    return text;
  },
};
