/**
 * We are using /:handle for public profiles and pages
 * Not all handles can be allowed, because we want to keep nice urls for most important pages.
 * For that reason we have black listed these:
 *
 * /confirm
 * /share
 * /welcome
 * /videocall
 * /401
 * /404
 * /500
 * /cloudsponge-proxy
 * /signup
 * /lockout
 * /verify
 * /layout
 * /contacts
 * /groups
 * /mycloud
 * /store
 * /myworld
 * /events
 * /chat
 * /settings
 * /profile/:uuid
 * /pages
 *
 *  these urls cannot be used to create handle (also backend has it's own list)
 *  any new route should be created with prefix /a/ - or should be added first to black listed urls in backend
 */

import EmberRouter from '@embroider/router';
import Router from '@ember/routing/router';
import Route from '@ember/routing/route';
import config from 'mewe/config/environment';
import dispatcher from 'mewe/dispatcher';
import { TextField } from '@ember/legacy-built-in-components';

export default class BaseRouter extends EmberRouter {
  location = config.locationType;

  rootURL = config.rootURL;
}

BaseRouter.map(function () {
  this.route('confirm');
  this.route('join-event', { path: '/e/:event_public_id' });
  this.route('join-event-front', { path: '/e-front/:event_public_id' });
  this.route('join-page-front', { path: '/p-front/:page_public_id' });
  this.route('join-page-front', { path: '/p-front/:page_public_id/*' });
  this.route('share-to-mewe', { path: '/share' });
  this.route('unsub-contact-invite', { path: '/mycontacts/invite-unsubscribe/:id' });
  this.route('unsub-group-invite', { path: '/groups/invite-unsubscribe/:id' });
  this.route('unsub-newsletter', { path: '/account/newsletter-unsubscribe/:id' });
  this.route('unsub-notifications', { path: '/account/notifications-unsubscribe/:id' });

  this.route('confirm-group-invite', { path: '/groups/invite-confirm/:id' });
  this.route('confirm-account', { path: '/account/confirm/:id' });
  this.route('set-new-password', { path: '/account/set-new-password/:id' });
  this.route('contacts-import', { path: '/contacts-import/:provider' });

  this.route('welcome');
  this.route('paypal-success');
  this.route('paypal-failure');
  this.route('externalhit');
  this.route('join-group', { path: '/join/:group_public_id' });
  this.route('join-group-front', { path: '/join-front/:group_public_id' }); // TODO used to get redirection from backend
  this.route('videocall');
  this.route('validate-email', { path: '/account/validate-email/:validation_id' });
  this.route('error-401', { path: '/401' });
  this.route('error-404', { path: '/404' });
  this.route('error-500', { path: '/500' });
  this.route('cloudsponge-proxy');
  this.route('signup');
  this.route('lockout');
  this.route('verify');

  this.route('avalanche');

  this.route('cms', function () {
    this.route('socialweb');
    this.route('about');
    this.route('move-to-web3');
    this.route('ambassadors');
  });

  this.route('ttw');
  this.route('storyblok', { path: '/cms/:path' }); // when all pages migrated to new design, this can be removed
  this.route('move-to-web3', { path: '/social-web/move-to-web3' }); // cms page

  this.route('login');
  this.route('register');

  this.route('web3-register', { path: '/web3' });
  this.route('web3-login', { path: '/web3/login' });
  this.route('web3-migrate', { path: '/web3/migrate' });
  this.route('web3-register-confirm', { path: '/account/dsnp/signup' });
  this.route('web3-login-confirm', { path: '/account/dsnp/login' });
  this.route('siwa-signin', { path: '/account/dsnp/siwa/signin' });
  this.route('siwa-identifier', { path: '/account/dsnp/siwa/identifier' });
  this.route('siwf-signin', { path: '/siwf/signin/application/:application_id' });

  this.route('help');
  this.route('privacy');
  this.route('terms');
  this.route('about');
  this.route('ambassadors');
  this.route('start');

  this.route('public-post', { path: '/post/show/:post_id' });
  this.route('to-front'); // proxy route for single-post, needed for link scrapping bots

  this.route('app', { path: '/' }, function () {
    // legacy routes for single posts - to be deleted afte a grace period
    this.route('page-single-post', { path: '/:id/show/:post_id' });
    this.route('group-single-post', { path: '/group/:group_id/show/:post_id' });
    this.route('event-single-post', { path: '/event/:event_id/show/:post_id' });

    this.route('myworld', function () {
      this.route('single-post', { path: '/show/:post_id' });
    });

    this.route('delete-account');
    this.route('layout'); // for testing/development
    this.route('component'); // for testing/development

    this.route('groups');
    this.route('mycloud');

    this.route('store', function () {
      this.route('index', { path: '/' });
      this.route('themes');
      this.route('subscriptions');
      this.route('emojis');
      this.route('stickers');
      this.route('product', { path: '/product/:item_id' });
    });

    this.route('events', function () {
      this.route('index', { path: '/' });
      this.route('birthdays');
      this.route('hosting');
      this.route('invited');
      this.route('past');
      this.route('upcoming');
    });

    this.route('chat', function () {
      this.route('index', { path: '/' });
      this.route('thread', { path: 'thread/:thread_id' }, function () {
        this.route('message', { path: '/message/:message_id' });
      });
      this.route('create-thread', { path: 'new-thread' });
      this.route('requests');
      this.route('requests-thread', { path: 'requests/thread/:thread_id' }, function () {
        this.route('message', { path: '/message/:message_id' });
      });
      this.route('requests-thread', { path: '/requests/thread/:thread_id' });
    });

    this.route('settings', function () {
      this.route('account', { path: '/' });
      this.route('preferences');
      this.route('groups');
      this.route('notification');
      this.route('purchases');
      this.route('privacy');
      this.route('apps');
    });

    this.route('event', { path: '/event/:event_id' }, function () {
      this.route('single-post-approval', { path: '/showApproval/:post_id' });
      this.route('feed', { path: '/' });
      this.route('feed-pending', { path: '/pending' });
      this.route('media');
      this.route('settings');

      this.route('attendees', function () {
        this.route('attending', { path: '/' });
        this.route('interested');
        this.route('notattending');
        this.route('invited');
        this.route('profile', { path: '/profile/:uuid' });
      });
    });

    //TODO: route to route( this, 'join') if user is logged out
    this.route('group', { path: '/group/:group_id' }, function () {
      this.route('documents');
      this.route('media');
      this.route('member-invite', { path: '/invite' });
      this.route('settings');
      this.route('single-post-approval', { path: '/showApproval/:post_id' });

      this.route('index', { path: '/' }, function () {
        this.route('feed', { path: '/' });
        this.route('feed-pending', { path: '/pending' });
      });

      this.route('members', function () {
        this.route('all', { path: '/' });
        this.route('admins');
        this.route('invited');
        this.route('blocked');
        this.route('pending');
        this.route('profile', { path: '/profile/:uuid' });
      });

      this.route('calendar', function () {
        this.route('birthdays');
        this.route('hosting');
        this.route('invited');
        this.route('past');
        this.route('upcoming');
      });

      this.route('schedule');
    });

    this.route('pages');
    this.route('profileid', { path: '/profile/:uuid' });

    this.route('publicid', { path: '/:id' }, function () {
      //Profile
      this.route('posts');
      this.route('bookmarks');
      //Common
      this.route('about');
      this.route('schedule');
      //Pages
      this.route('feed');
      this.route('about');
      this.route('notifications');
      this.route('statistics', function () {
        this.route('index', { path: '/' });
        this.route('followers');
        this.route('posts');
      });
      this.route('media', function () {
        this.route('stream', { path: '/' });
        this.route('tags');
        this.route('albums');
        this.route('album', { path: '/album/:album_id' });
      });
      this.route('settings', function () {
        this.route('general', { path: '/' });
        this.route('contact');
        this.route('admin');
        this.route('moderation');
        this.route('subscription');
        this.route('notifications');
        this.route('permissions');

        this.route('users', function () {
          this.route('followers');
          this.route('banned');
        });
      });
    });

    // eslint-disable-next-line ember/no-shadow-route-definition
    this.route('page', { path: '/p/:url_id' });
  });
  // eslint-disable-next-line ember/no-shadow-route-definition
  this.route('invite', { path: '/:contact_public_id' });
  // eslint-disable-next-line ember/no-shadow-route-definition
  this.route('join-page', { path: '/p/:url_id' });
  this.route('404', { path: '/*path' });
});

if (window.history && window.history.pushState) {
  Router.reopen({
    init() {
      this._super(...arguments);
      dispatcher.setCtx(this);
    },

    location: 'history',
  });
} else {
  Router.reopen({
    init() {
      this._super(...arguments);
      dispatcher.setCtx(this);
    },
  });
}

Route.reopen({
  dispatch: dispatcher.dispatch,
});

TextField.reopen({
  attributeBindings: ['data-testid'],
});
