import EmberObject, { set } from '@ember/object';
import { each } from 'lodash';
import { default as fuHelper } from 'mewe/utils/fileupload-utils';
import { resizeImage, loadImage } from 'mewe/utils/fileupload-utils';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import { isVideoLimitExceeded, getVideoPreview } from 'mewe/utils/video-utils';
import FunctionalUtils from 'mewe/shared/functional-utils.js';
import MathUtils from 'mewe/utils/math-utils';
import Session from 'mewe/shared/session';
import {
  fileUploadLimit,
  photoMaxWeightToResizeInBrowser,
  photoMaxWeightToUploadToServer,
  photoMaxWidthHeight,
  prettyWeight as weight,
} from 'mewe/constants';
import axios from 'axios';
import VideoApi from 'mewe/api/video-api';
import PS from 'mewe/utils/pubsub';
import config from 'mewe/config';

const CancelToken = axios.CancelToken;

let { isDefined } = MiscellaneousUtils;

const bindExisting = (fnName, context) => {
  if (context.get(fnName)) context[fnName] = context.get(fnName).bind(context);
};

const _imageFileTypes = /^image\/(gif|jpeg|png|svg\+xml)$/;
const _videoFileTypes = /^video\//;

const _isImage = (file) => _imageFileTypes.test(file.type);
const _isVideo = (file) => _videoFileTypes.test(file.type);

export default EmberObject.extend({
  filesToSend: 0,
  autoUpload: false,
  timeoutId: null,
  isImageUpload: false,
  isVideoUpload: false,
  isFileUpload: false,

  init() {
    const inputQuery = this.formEl.getElementsByTagName('input');

    if (!inputQuery) throw new Error('Cannot find element upload form input');

    this.input = inputQuery[0];

    this.inputChangeBind = this.inputChange.bind(this);
    this.input.addEventListener('change', this.inputChangeBind);

    bindExisting('storeUploadRequest', this);
    bindExisting('setUploadInProgress', this);
    bindExisting('onFileAdded', this);
    bindExisting('onFileLoaded', this);
    bindExisting('renderPhoto', this);
    bindExisting('cancelAllUploads', this);

    if (isDefined(this.dropZoneEl)) {
      this.dragOverHandler = (e) => {
        e.preventDefault();

        const files = e.dataTransfer;

        if (files && files.items && files.items[0] && files.items[0].kind !== 'file') {
          return;
        }

        // don't show drop area for chat if any dialog is opened
        if (document.querySelector('.dialog_wrapper')?.length > 0 || document.body.classList.contains('postbox-open')) {
          return;
        }

        /**
         * dialog is on the top of other content like chats or
         * chat window, don't show drop area for chat in such cases
         */
        const isDialogOpened = !!document.querySelector('.dialog_wrapper');

        if (!this.timeoutId && !isDialogOpened) {
          // limit to trigger only once while dragover
          if (this.chatEl.classList.contains('chat--minimized')) {
            this.chatEl.classList.add('to-minimize', 'chat--small');
            this.chatEl.classList.remove('chat--minimized'); // expand chat while file dragging
          }

          this.chatEl.querySelectorAll('.drop-overlay').forEach((el) => el.classList.add('to-drop'));
          document.querySelectorAll('.chat-window_messages').forEach((el) => el.classList.add('drop-mode'));
        } else {
          clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(() => {
          if (this.chatEl.classList.contains('to-minimize')) {
            this.chatEl.classList.add('chat--minimized');
            this.chatEl.classList.remove('to-minimize', 'chat--small'); // minimize chat if it was minimized before dragging
          }
          this.chatEl.querySelectorAll('.drop-overlay').forEach((el) => el.classList.remove('to-drop'));
          document.querySelectorAll('.chat-window_messages').forEach((el) => el.classList.remove('drop-mode'));
          this.timeoutId = null;
        }, 100);
      };

      this.dragOverHandlerBind = this.dragOverHandler.bind(this);
      document.addEventListener('dragover', this.dragOverHandlerBind);

      this.dropHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.add(e.dataTransfer.files, true);
      };

      this.dropHandlerBind = this.dropHandler.bind(this);
      this.dropZoneEl.addEventListener('drop', this.dropHandlerBind);

      if (this.storeEventListener && typeof this.storeEventListener === 'function') {
        this.storeEventListener('dragover', this.dragOverHandlerBind);
      }
    }

    if (this.pasteZoneEl) {
      this.pasteHandler = (e) => {
        if (e.clipboardData && e.clipboardData.files && e.clipboardData.files[0]) {
          this.add(e.clipboardData.files, true);
        }
      };

      this.pasteHandlerBind = this.pasteHandler.bind(this);
      this.pasteZoneEl.addEventListener('paste', this.pasteHandlerBind);
    }
  },

  isUploadFinished() {
    if (this.autoUpload) {
      return this.filesToSend === 0;
    } else {
      let fileInProgress = this.files ? this.files.find((file) => isDefined(file.tempId)) : null;
      return !isDefined(fileInProgress); // if any file has tempId then it's still not uploaded
    }
  },

  finishUploading() {
    window.onbeforeunload = null;

    if (typeof this.setUploadInProgress === 'function') {
      this.setUploadInProgress(false);
    }

    if (typeof this.finishedUploading === 'function') {
      this.finishedUploading();
    }
  },

  inputChange() {
    this.add(this.input.files);
  },

  add(files, isDropOrPaste) {
    if (this.autoUpload) {
      this.filesToSend = files.length;
    }

    each(files, (currentFile) => {
      currentFile.tempId = MathUtils.generateId();

      const isImage = _isImage(currentFile);
      const isVideo = _isVideo(currentFile);
      const isFile = !isImage && !isVideo;

      if (isDropOrPaste) {
        if ((isImage && !this.isImageUpload) ||
          (!isImage && this.isImageUpload) ||
          (isVideo && !this.isVideoUpload) ||
          (!isVideo && this.isVideoUpload) ||
          (isFile && !this.isFileUpload) ||
          (!isFile && this.isFileUpload)
        ) {
          return;
        }
      }

      // prevent doubled upload after paste event
      if (this.originalFile && currentFile === this.originalFile) {
        return false;
      } else {
        this.set('originalFile', currentFile); // store file so it can be compared later with pasted file
      }

      if (isVideo) {
        if (isVideoLimitExceeded(currentFile, this.currentUserId)) {
          fuHelper.fileSizeErrorDialog('video');
          return false;
        }
      } else {
        if (currentFile?.size >= fileUploadLimit) {
          fuHelper.fileSizeErrorDialog();
          return false;
        }
      }

      if (typeof this.onFileAdded === 'function') {
        let addFileReturn = this.onFileAdded({
          videoPreviewSrc: getVideoPreview(currentFile).videoPreviewSrc,
          tempId: currentFile.tempId,
          name: currentFile.name,
          type: currentFile.type,
          isVoice: currentFile.isVoice,
          isVideo: isVideo,
          isImage: isImage,
        });

        //if onFileAdded return false ignore that file (limit reached)
        //only if checkAddFileReturn is set to true
        if (this.checkAddFileReturn && !addFileReturn) {
          return;
        }
      }

      // setUploadInProgress callback has to be called after onFileAdded callback above
      if (typeof this.setUploadInProgress === 'function') {
        this.setUploadInProgress(1, currentFile.tempId);
      }

      if (isVideo) {
        this.initVideoUpload(currentFile);
      } else if (
        isImage &&
        currentFile.type.indexOf('image/gif') == -1 &&
        currentFile.size >= photoMaxWeightToResizeInBrowser
      ) {
        resizeImage(currentFile, {
          maxWidth: photoMaxWidthHeight,
          maxHeight: photoMaxWidthHeight,
        }).then((file) => {
          // photo after resizing it still too big to upload to server
          if (file.size >= photoMaxWeightToUploadToServer) {
            PS.Pub('open.generic.dialog', {
              title: __('Photo upload error'),
              message: __('Photo should not exceed {limit}', { limit: weight(photoMaxWeightToUploadToServer) }),
            });
            return;
          }

          this.loadImage(currentFile);

          this.uploadFile(currentFile.tempId, file);
        });
      } else {
        if (isImage) {
          this.loadImage(currentFile);
        }

        this.uploadFile(currentFile.tempId, currentFile);
      }

      window.onbeforeunload = this.confirmExit;
    });
  },

  initVideoUpload(file) {
    let params = {
      doc: {
        title: file.name,
      },
      length: file.size,
    },
    async = false;

    VideoApi.authorize(params, async)
      .then((retdata) => {
        this.uploadVideo(file, retdata);
      })
      .catch((jqXHR) => {
        if (jqXHR.data && jqXHR.data.errorCode === 700) {
          this.dynamicDialogs?.openDialog('store/store-item-storage-dialog', { storageAlert: true });
        }
      });
  },

  uploadFile(tempId, file) {
    let formData = new FormData();
    formData.append('file', file);

    const source = CancelToken.source();

    axios({
      url: this.formEl.action,
      method: 'POST',
      cancelToken: source.token,
      headers: { 'X-CSRF-Token': Session.getCsrfToken() },
      onUploadProgress: (data) => {
        if (data.lengthComputable) {
          this.progress(tempId, data);
        }
      },
      data: formData,
    })
      .then((res) => {
        this.done(tempId, res.data);
      })
      .catch((e) => {
        this.fail(tempId, e.response);
      });

    if (typeof this.storeUploadRequest === 'function') {
      source.tempId = tempId;
      this.storeUploadRequest(source); // store request in chat component to make it possible to abort if needed
    }
  },

  uploadVideo(file, retdata) {
    const tempId = file.tempId;
    let formData = new FormData();

    formData.append('AWSAccessKeyId', retdata.awsAccessKeyId);
    formData.append('signature', retdata.signature);
    formData.append('policy', retdata.policy);
    formData.append('acl', retdata.acl);
    formData.append('key', retdata.key);
    formData.append('success_action_status', retdata.successActionStatus);
    formData.append('x-amz-security-token', retdata.sessionToken);
    formData.append('file', file);

    const source = CancelToken.source();

    axios({
      url:
        config.environment == 'local' ? '/video-proxy' : 'https://' + retdata.instanceBucketName + '.s3.amazonaws.com',
      method: 'POST',
      cancelToken: source.token,
      onUploadProgress: (data) => {
        if (data.lengthComputable) {
          this.progress(tempId, data);
        }
      },
      data: formData,
    })
      .then((res) => {
        this.done(tempId, res.data, retdata.uploadFolder);
      })
      .catch((e) => {
        this.fail(tempId, e.response);
      });

    if (typeof this.storeUploadRequest === 'function') {
      source.tempId = tempId;
      this.storeUploadRequest(source); // store request in chat component to make it possible to abort if needed
    }
  },

  loadImage(file) {
    const tempId = file.tempId;
    const params = {
      maxWidth: 177,
      maxHeight: 162,
    };

    loadImage(file, params)
      .then((canvas) => {
        if (typeof this.renderPhoto === 'function') {
          this.renderPhoto(canvas.toDataURL(), tempId);
        }
      })
      .catch((error) => {
        if (error) {
          fuHelper.wrongFormatMsg();
        }

        return false;
      });
  },

  progress(tempId, data) {
    let progress = parseInt((data.loaded / data.total) * 100, 10);

    if (typeof this.setUploadInProgress === 'function') {
      this.setUploadInProgress(progress, tempId);
    }
  },

  done(tempId, data, videoUploadFolder) {
    if (this.autoUpload) {
      this.get('send')(data.id, { tempId, videoUploadFolder });
      this.filesToSend--;
    } else {
      if (typeof this.onFileLoaded === 'function') {
        this.onFileLoaded(tempId, data.id, videoUploadFolder);
      }

      if (this.originalFile && tempId === this.originalFile.tempId) {
        this.originalFile = null; // clean memory
      }
    }

    this.input.value = ''; // reset value so that selecting same file will be noticed by onChange listener

    if (this.isUploadFinished()) this.finishUploading();
  },

  fail(tempId, response = {}) {
    if (response.status == 401) return;

    if (response.data && response.data.data) {
      if (response.data.data.errorCode === 703) {
        FunctionalUtils.error(__('Sorry, resolution of this photo is too big'));

        if (typeof this.cancelUpload === 'function') {
          this.cancelUpload(tempId);
        }
      }
    }

    if (this.autoUpload) {
      this.filesToSend--;
    } else {
      let abortedFile = this.files ? this.files.find((el) => el.tempId == tempId) : null;

      if (isDefined(abortedFile)) {
        set(abortedFile, 'tempId', null);
      }
    }

    this.input.value = ''; // reset value so that selecting same file will be noticed by onChange listener

    if (this.isUploadFinished()) {
      this.finishUploading();
    }
  },

  destroy: function () {
    this.input.removeEventListener('change', this.inputChangeBind);
  },

  confirmExit: function () {
    return __('Your file is still uploading. If you navigate away from this page, the upload will be interrupted.');
  },
});
