import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { escape } from 'lodash';
import { later } from '@ember/runloop';

import FunctionalUtils from 'mewe/shared/functional-utils';
import EventsApi from 'mewe/api/events-api';
import FeedApi from 'mewe/api/feed-api';
import DiscoverApi from 'mewe/api/discover-api';
import { isFeatureHintValid } from 'mewe/utils/feature-hint';
import { maxPostsForFTUE, Theme, FeedTypes } from 'mewe/constants';
import { openFtueRecommendations } from 'mewe/utils/dialogs-common';
import { isMac } from 'mewe/shared/utils';
import storage from 'mewe/shared/storage';
import { keys } from 'mewe/shared/keys';

export default class AppMyworld extends Component {
  @service account;
  @service settings;
  @service router;
  @service chat;
  @service dynamicDialogs;
  @service('postboxUpload') uploadService;

  @tracked didSetInterests = true;
  @tracked campaignPost = null;
  @tracked fadeOutCampaignPost;

  actionalbeHashes = [
    '#contactInvitation',
    '#video',
    '#not4sale',
    '#application',
    '#contacts',
    '#topic',
    '#chat',
    '#eventParticipation',
    '#email-validated',
    '#email-not-validated',
    '#dsnp', // used somewhere else, indicates opening App for the first time after DSNP registration
  ];

  constructor() {
    super(...arguments);

    const hashWithoutParams = this.model.app.locationHash?.split('?')[0];

    if (this.actionalbeHashes.includes(hashWithoutParams)) {
      this.openFromHashWithCurrentUser(hashWithoutParams);
    }

    DiscoverApi.getSelectedInterests().then((data) => {
      storage.set(keys.didSetInterests, !!data.interests?.length);
      this.didSetInterests = !!data.interests?.length;
    });

    FeedApi.getCampaignPost().then((data) => {
      this.campaignPost = data.userCampaigns?.[0];
    });
  }

  get model() {
    return this.args.model;
  }

  get shouldShowInvitingPosts() {
    // getters used because changes were not observed otherwise, at least when feed.type has changed (SG-42097)
    return (
      get(this, 'model.feed.posts.length') <= maxPostsForFTUE &&
      (get(this, 'model.feed.type') === FeedTypes.ALL || get(this, 'model.feed.type') === FeedTypes.CONTACTS) &&
      !get(this, 'model.feed.isFetching') &&
      !get(this, 'model.hash')
    );
  }

  get showInterestsLink() {
    return (
      get(this, 'model.feed.type') === FeedTypes.DISCOVER ||
      ![this.didSetInterests, storage.get(keys.didSetInterests)].includes(true)
    );
  }

  get showCampaignPost() {
    return (
      this.campaignPost &&
      ((get(this, 'model.feed.type') === FeedTypes.ALL && ~this.campaignPost.showInTabs?.indexOf('all')) ||
        (get(this, 'model.feed.type') === FeedTypes.DISCOVER && ~this.campaignPost.showInTabs?.indexOf('discover')))
    );
  }

  get showSuggestedGroups() {
    if (this.model.tag) return false;

    return get(this, 'model.feed.type') === FeedTypes.ALL || get(this, 'model.feed.type') === FeedTypes.GROUPS;
  }

  get downloadAppLink() {
    return isMac() ? '/download/mac' : '/download/windows';
  }

  openFromHashWithCurrentUser(hash) {
    if (~hash.indexOf('contactInvitation')) {
      this.dynamicDialogs.openDialog('invitations-dialog', { isContactInvitation: true });
    } else if (~hash.indexOf('#video') || ~hash.indexOf('#not4sale')) {
      this.dynamicDialogs.openDialog('share-mewe-dialog');
    } else if (~hash.indexOf('#application')) {
      this.showApplicationAlert();
    } else if (~hash.indexOf('#contacts')) {
      this.showContactInvitationInfo(hash);
    } else if (~hash.indexOf('#topic')) {
      let options = {};
      if (~hash.indexOf('?name')) {
        // opening particular topic in public directory popup
        // /myworld#topic?name=heart-of-all-women
        let fetchParamsReqexp = /#topic\?name=([^&]*)/,
          execResults = fetchParamsReqexp.exec(hash);

        if (execResults) {
          let name = decodeURI(execResults[1]);
          if (name) {
            options.openTopic = name;
          }
        }
      }

      // opening public directory popup, even if there is no name specified
      this.openPublicDirectory(options);
    } else if (~hash.indexOf('#chat')) {
      if (~hash.indexOf('?threadId')) {
        // opening chat via threadId - link from 'You have new chat' mail
        ///myworld#chat?threadId=555f24d4d4c6ac1fac95f470
        let fetchParamsReqexp = /#chat\?threadId=([^&]*)/,
          execResults = fetchParamsReqexp.exec(hash);

        if (execResults) {
          let threadId = decodeURI(execResults[1]);
          if (threadId) {
            this.chat.openThreadById(threadId);
          }
        }
      } else if (~hash.indexOf('?userId')) {
        // opening chat via userId - link from accepting contact mail
        var fetchParamsReqexp = /#chat\?userId=([^&]*)/;
        var execResults = fetchParamsReqexp.exec(hash);
        if (execResults) {
          var userId = decodeURI(execResults[1]);
          if (userId) {
            // TODO - left temporarily, chat.open is never subscribed - I think using openThreadByParticipants on injected chat is perfectly OK, but maybe I'm wrong ;)
            this.chat.openThreadByParticipants([
              {
                userId,
              },
            ]);
          }
        }
      }
    } else if (~hash.indexOf('eventParticipation')) {
      this.setEventParticipation(hash);
    } else if (hash === '#email-validated') {
      FunctionalUtils.info(__('You confirmed your email address.'));
    } else if (hash === '#email-not-validated') {
      FunctionalUtils.error(__('Email address not found or is already confirmed.'));
    }
  }

  openPublicDirectory(options) {
    this.dynamicDialogs.openDialog('browse-communities-dialog', {
      theme: Theme.GROUP,
      options,
    });
  }

  showContactInvitationInfo(hash) {
    var userFullName = '',
      fetchParamsReqexp = /#contacts\?userName=([^&]*)/,
      execResults = fetchParamsReqexp.exec(hash),
      currentUser = this.account.activeUser,
      currentUserFirstName = escape(currentUser.firstName);

    if (execResults) {
      userFullName = decodeURIComponent(execResults[1]);
    } else {
      return false;
    }

    let textBtn = __('Close'),
      textHeader = __('Hi {firstName}!', { firstName: currentUserFirstName });

    isFeatureHintValid('ftue-tour')
      .then(({ timesViewed }) => {
        if (timesViewed === 0) {
          textBtn = __(`Let's Go!`);
          textHeader = __('Welcome to MeWe, {firstName}!', { firstName: currentUserFirstName });
        }

        this.openSentContactConfirmation(userFullName, textBtn, textHeader);
      })
      .catch(() => {
        this.openSentContactConfirmation(userFullName, textBtn, textHeader);
      });
  }

  showApplicationAlert() {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: __('Application to the group'),
      message: __(
        `Your application has been sent to the group owner for approval. You'll be notified when the owner approves your application to join.`
      ),
      noEscaping: true,
      okButtonText: __('OK'),
    });
  }

  openSentContactConfirmation(userFullName, textBtn, textHeader = 'ok') {
    this.dynamicDialogs.openDialog('simple-dialog-new', {
      title: textHeader,
      message: __(
        'Your request to be Contacts has been sent to {userName}, and you will be notified as soon as they accept.',
        {
          userName: escape(userFullName),
        }
      ),
      noEscaping: true,
      okButtonText: textBtn,
    });
  }

  setEventParticipation(hashParam) {
    let answers = ['Attending', 'NotAttending', 'Interested'],
      hash = hashParam || window.location.hash,
      eventIndex = hash.indexOf('eventId='),
      answerIndex = hash.indexOf('eventParticipation='),
      eventId = hash.slice(eventIndex + 8),
      answer = hash.slice(answerIndex + 19, answerIndex + 20),
      participation = answers[answer];

    if (!participation) return;

    EventsApi.setParticipation(eventId, participation)
      .then(() => {
        let msg;

        if (participation === 'Attending') {
          msg = __(`You've confirmed you're attending the event`);
        } else if (participation === 'NotAttending') {
          msg = __(`You've declined the invitation to event`);
        } else {
          msg = __('You may be attending the event');
        }

        FunctionalUtils.info(msg);
      })
      .catch(() => FunctionalUtils.showDefaultErrorMessage());
  }

  @action
  setFeedParams(params) {
    this.args.setFeedParams(params);
  }

  @action
  openFtueCategories() {
    openFtueRecommendations(this.dynamicDialogs, 'myworld-feed');
  }

  @action
  openHashTagFromTop(tag) {
    if (tag === '') {
      this.args.setFeedParams({ tag: tag || null, scope: FeedTypes.ALL });
    } else {
      this.args.setFeedParams({ tag: tag || null, scope: FeedTypes.HASHTAG });
    }
  }

  @action
  removeCampaignPost() {
    this.fadeOutCampaignPost = true;

    later(() => {
      this.campaignPost = null;
    }, 150); // 150ms is fading transition time
  }
}
