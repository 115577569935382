import ApiUtil from './api';

class Api extends ApiUtil {

  // get the application details, requested permissions, etc.
  postSiwf(applicationId, params) {
    return this.post(`/applications/${applicationId}/siwf/signin`, {
      data: JSON.stringify(params),
    });
  }

  // accept requested permissions, optionaly pass first/last name in case of new user signup
  acceptSiwf(applicationId, params) {
    return this.post(`/applications/${applicationId}/siwf/accept`, {
      data: JSON.stringify(params),
    });
  }

}

export default new Api();
