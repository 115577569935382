import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import SiwfApi from 'mewe/api/siwf-api';
import FunctionalUtils from 'mewe/shared/functional-utils';

export default class MwSiwfSignin extends Component {

  @tracked firstName = '';
  @tracked lastName = '';
  @tracked promisesToWaitFor = [];
  @tracked appData;
  @tracked error;
  @tracked isSubmitInProgress;
  @tracked requireNames;
  otp;

  constructor() {
    super(...arguments);

    if (!this.args.model.applicationId || !this.args.model.urlParams.authorizationCode) {
      this.error = 'invalidUrl';
      FunctionalUtils.error('Missing applicationId or authorizationCode');
      return;
    }

    const siwfPromise = SiwfApi.postSiwf(
      this.args.model.applicationId,
      {
        authorizationCode: this.args.model.urlParams.authorizationCode,
      }
    );

    this.promisesToWaitFor.push(siwfPromise);

    siwfPromise.then((data) => {
      this.appData = data.app;
      this.requireNames = data.requireNames;
      this.otp = data.otp;
    }).catch((err) => {
      FunctionalUtils.error('Error fetching SIWF data for application');
      console.log(err);
    });
  }

  get firstNameValue() {
    return this.firstName.trim();
  }

  get lastNameValue() {
    return this.lastName.trim();
  }

  get isSubmitDisabled() {
    return this.isSubmitInProgress || this.requireNames && (!this.firstNameValue || !this.lastNameValue);
  }

  @action
  submitAction() {
    this.isSubmitInProgress = true;

    SiwfApi.acceptSiwf(
      this.args.model.applicationId,
      {
        firstName: this.firstNameValue,
        lastName: this.lastNameValue,
        otp: this.otp,
      }
    ).then((data) => {
      if (data.location) {
        window.location.href = decodeURIComponent(data.location);
      }
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      this.isSubmitInProgress = false;
    });
  }

}